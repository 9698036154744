import { useEffect } from "react";
import { useHookstate } from '@hookstate/core';
import { createClient } from "@supabase/supabase-js";
import orgChart from './img/p8.png';
import fam1 from './img/fam1.jpg';
import fam2 from './img/fam2.jpg';
import './App.css';

let supabase = createClient(process.env.REACT_APP_API_URL, process.env.REACT_APP_API_KEY);

export let App = () => {
 let users = useHookstate([])

 useEffect(() => {
  //getData();
 }, []);


 let getData = async () => {
  let { data } = await supabase.from("users").select();
  users.set(data);
 };

 let updateMobile = async (userPk, mobile) => {
  await supabase.from("users").update({ mobile }).eq("userPk", userPk);
  getData();
 };


 return (
  <>
   {/* <div>
    {users.get().map((item) => (
     <div key={item.userPk}>
      <span>{`${item.firstName} ${item.lastName} ${item.mobile}`}</span>
      <input
       type='number'
       defaultValue={item.mobile || ''}
       onChange={(e) => updateMobile(item.userPk, e.target.value)}
      />
     </div>
    ))}
   </div> */}
   <div className="header">
    Castillo Family Reunion
   </div>


   <div className="msg">
    Hi mi Familia, <br></br>
    Years are passing by so quickly, many of us are growing up and just a few of us are getting old LOL!!  I would love for us all to start a “Castillo” Family Tradition to get together at least once every year so we can all keep in touch and stay connected.  Please bookmark this website as a favorite.  I will maintain this website with shared pictures of our future gatherings!!!  I’ll be communicating with you all very soon via text messaging so we can set a date for our first “Castillo Family Reunion”.  More details to come soon….
    <br></br>
    <br></br>
    Your son/father/brother/uncle/cousin/nephew David Castillo Senior.
    <br></br>
    <br></br>
    Love you ALL!!!
    <br></br>
    <div className="txt">Scroll Down for Family pics!!!</div>
   </div>

   <div>
    <img src={orgChart} alt='Org Chart' />
    <img src={fam1} alt='fam1' />
    <img src={fam2} alt='fam2' />
   </div>
  </>
 );

};





/*


 return (
  <>
  m
  <ul>
   
   { users.get() ? 
   
   users.get().map((item) => (
    <li key={item.userPk}>{item.firstName}</li>
   ))

   : null
   
   }
  </ul>

<button onClick={log}>ddd</button>
  </>
 );
*/


